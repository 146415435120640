import React from "react";

import { Button } from "../../components/Button";
import { Card } from "../../components/Card";
import { Grid, Row, Col } from "../../components/Grid";
import { Link } from "../../components/Link";
import { Page } from "../../components/Page";

import "./home.scss";

export default function HomePage() {
  return (
    <Page
      className="home"
      molecules="bottom"
      title="SparkMedical.com® | Medical Information for US Health Care Professionals"
      description="SparkMedical.com® is a Spark Therapeutics website for US healthcare professionals to access medical, scientific, and educational resources."
      subject=""
      canonical="/"
    >
      <section className="home-hero">
        <Grid>
          <Row>
            <Col xs={12}>
              <h1 className="white">Welcome to SparkMedical.com&reg;</h1>
              <p className="white small">
                <em>A resource for US healthcare professionals</em>
              </p>
              <p className="lede white">
                A Spark Therapeutics website for US healthcare professionals to
                access medical, scientific, and educational resources.
              </p>
              <Button
                primary
                large
                search
                link="/medical-and-scientific-resources"
                title="Search for available medical information and resources"
                analytics={{
                  category: "Calls to Action"
                }}
              >
                Search Medical &amp;{" "}
                <span style={{ whiteSpace: "nowrap" }}>
                  Scientific Resources
                </span>
              </Button>
            </Col>
          </Row>
        </Grid>
      </section>
      <section className="home-cards">
        <Grid fluid>
          <Row>
            <Col xs={12} sm={6} lg={4}>
              <Card small title="Give us a call">
                <p>
                  If you have an inquiry about a Spark product or therapeutic
                  area, give us a call.
                </p>
                <p>
                  <span className="nowrap">1-866-MISPARK</span> /{" "}
                  <span className="nowrap">1-866-647-7275</span> <br />
                  <span className="nowrap">M-F 8am-8pm</span> ET
                </p>
              </Card>
            </Col>
            <Col xs={12} sm={6} lg={4}>
              <Card
                title="Request Medical Information"
                small
                link="/contact-spark/request-medical-information"
                analytics={{
                  category: "Calls to Action",
                  label: "Request Medical Information"
                }}
              >
                <p>
                  Have a question about a Spark product or therapeutic area? You
                  can submit it here.
                </p>
              </Card>
            </Col>

            <Col xs={12} sm={6} lg={4}>
              <Card
                small
                title="Report an adverse event or product complaint"
                link="https://sparktx.com/aepc/"
                analytics={{
                  category: "Calls to Action",
                  label: "Report AE / Product Effect"
                }}
              >
                <p>
                  Report an adverse event or product quality complaint here.
                </p>
              </Card>
            </Col>
          </Row>
        </Grid>
        <Grid fluid>
          <Row className="pt-3 pb-3">
            <Col xs={12} lg={8}>
              <h2>
                Our goal is to address unmet needs in rare genetic diseases.
              </h2>
              <p>
                Every day, our team of healthcare professionals is inspired by
                the patients we serve. Driven by a passion for what we do, we
                strive to address and bring focus to unmet needs in rare genetic
                diseases.{" "}
                <strong>
                  <Link
                    to="/about-spark/medical-affairs-team"
                    title="Learn more about the Spark Therapeutics Medical Affairs Team"
                  >
                    Meet Spark Medical Affairs
                  </Link>
                </strong>
                .
              </p>
            </Col>
          </Row>
        </Grid>
      </section>
    </Page>
  );
}
